<template>
  <div>
    <b-button v-b-modal.createPredictionModal v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="gradient-success"
      block>
      สร้างกิจกรรม
    </b-button>

    <b-modal v-model="modal" size="lg" id="createPredictionModal" title="สร้างกิจกรรม">

      <b-form-group label="ประจำวันที่:" label-for="date">
        <flat-pickr id="date" v-model="date" class="form-control"/>
      </b-form-group>

      <div class="row">
        <div class="col-3">
          <b-form-group label="เงินรางวัล 3 ตัวบน (บาท):" label-for="reward">
            <b-form-input id="reward" v-model="t3_reward" type="number" required></b-form-input>
          </b-form-group>
        </div>
        <div class="col-3">
          <b-form-group label="เงินรางวัล 2 ตัวล่าง (บาท):" label-for="reward">
            <b-form-input id="reward" v-model="b2_reward" type="number" required></b-form-input>
          </b-form-group>
        </div>
        <div class="col-3">
          <b-form-group label="Chip ที่ต้องใช้:" label-for="chip-required">
            <b-form-input id="chip-required" v-model="chip_required" type="number" required></b-form-input>
          </b-form-group>
        </div>
        <div class="col-3">
          <b-form-group label="ปิดการทายผลเวลา:" label-for="close_at">
            <flat-pickr id="close_at" v-model="close_at" :config="closeAtConfig" class="form-control" />
          </b-form-group>
        </div>
      </div>

      <template #modal-footer>
        <b-button variant="success" @click="submit()">
          ยืนยัน
        </b-button>
        <b-button variant="danger" @click="cancel()">
          ยกเลิก
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment-timezone';
import 'flatpickr/dist/flatpickr.css';
export default {
  components: {
    BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
    flatPickr
  },
  directives: {
    Ripple
  },
  props: ['competitions'],
  emits: ['changeDate'],
  filters: {
    convertTime(date) {
      return moment(date).tz('Asia/Bangkok').format('HH:ss')
    },
  },
  data: () => ({
    modal: false,
    matches: [],
    t3_reward: 3000,
    b2_reward: 2000,
    chip_required: 1,
    date: new Date(),
    close_at: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 15, 0),
    closeAtConfig: {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    },
    dateConfig: {
      // onChange: this.x().bind(this)
    }
  }),
  methods: {
    submit() {
      this.$emit('submit', {
        t3_reward: this.t3_reward,
        b2_reward: this.b2_reward,
        date: this.date,
        close_at: this.close_at
      })
      this.date = new Date();
      this.close_at = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 15, 0);

      this.modal = false
    },
    cancel() {
      this.modal = false;

      this.date = new Date();
      this.close_at = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 15, 0);
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>